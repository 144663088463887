import Vue from "vue";
import Vuex from "vuex";
//import VuexPersistence from 'vuex-persist';

import * as brew from "@/store/modules/breweries";

/*const vuexPersist = new VuexPersistence({
    key: 'pub-crawl',
    storage: window.localStorage
})*/

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,

    modules: {
        brew,
    },
    //plugins: [vuexPersist.plugin],
});