import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "info" */ '../views/Info.vue')
  },
  {
    path: '/passport/:id?',
    name: 'Passport',
    component: () => import(/* webpackChunkName: "passport" */ '../views/Passport.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/Scan.vue')
  },
  {
    path: '/directions',
    name: 'Directions',
    component: () => import(/* webpackChunkName: "directions" */ '../views/Directions.vue')
  },
  { path: '/brewery/:id', name: 'Brew', component: () => import(/* webpackChunkName: "directions" */ '../views/Brewery.vue') },

  {
    // will match everything
    path: '*',
    component: () => import(/* webpackChunkName: "directions" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
