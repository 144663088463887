<template>
  <div>
    <h2>Saturday, March 12, 2022</h2>
    <h3>1pm - 4pm</h3>
    <v-img
      :lazy-src="require('@/assets/images/pub-crawl-logo.png')"
      max-height="400"
      max-width="400"
      :src="require('@/assets/images/pub-crawl-logo.png')"
      class="ma-2"
    ></v-img>
    <p>It's the 2022 St. Patrick's Day Pub Crawl!</p>

    <p>
      Back for the 9th year, Kalen Marketing Solutions presents the annual St.
      Patrick's Day Pub Crawl. All proceeds for this event will be donated to
      Jason's Friends and Special Olympics.
    </p>

    <p>
      We have a preset course & you have only 3 hours to complete it. Upon
      entering each participating location you can grab your 4oz drink, which
      your registration fee covers, get your stamp of completion at our booth
      then move on to the next location. Check in & same day registration begins
      at 12pm. Our crawl is scheduled from 1pm-4pm.
    </p>

    <p>
      The first drink is being sponsored by Skull Tree Brewing inside Gaslight
      Social at check in. We will send out our first wave of participants
      beginning at 1pm where they will follow our course to Yellowstone Garage
      for their second stop, hop over to Frontier Brewing for their third drink,
      skip on over to Black Tooth for a fourth stop, proceed to The Office for
      fifth drink, jump over to Racca's for drink number six, make their move to
      the Urban Bottle for their seventh stop. Finally participants will mosey
      on over to Gaslight Social for their final drink. So mark your calendars
      for Saturday, March 12th.
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
p {
  color: white;
}
</style>