export const results = [

    {
        "objectId": "n7H170lvBQ",
        "placeId": "ChIJbZteoUu9YIcR16iaxVGCmKE",
        "name": "Black Tooth",
        "description": "Sheridan Wyoming’s most highly awarded brewery is located in the heart of the wild west, and at the base of the majestic Big Horn Mountains. At Black Tooth Brewery we are creating fun, unique, and quality craft beer, and have been doing so for over 8 years.\nThe vision of being a leader in creating quality craft beer in the Rocky Mountain region has now become a reality. Black Tooth now has four of its flagship beers, Saddle Bronc (Brown Ale), Bomber Mountain (Amber Ale), Hot Streak (IPA) and Copper Mule (Cream Ale) available in 6-Pack Cans and Draught statewide in Wyoming as well as parts of South Dakota and Montana.\nWithin the taproom doors you will find a relaxing yet vivacious tasting room where locals and travelers alike can enjoy a fresh pint, from a wide range of Black Tooth brews. So if you’re passing through the wild states of the west make sure you stop into the taproom or grab yourself a 6-pack or pint at a local watering hole and #Drink The West!",
        "logoURL": "BlackTooth.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "322 S David St Suite A\n Casper, WY 82601",
        "phone": "(307) 577-5460",
        "tel": "307-577-5460",
        "siteURL": "https://www.blacktoothbrewingcompany.com/",
        "checked": false
    },
    {
        "objectId": "kzEas2HonG",
        "placeId": "ChIJA-tqPKK8YIcRS9uDyh55i_A",
        "name": "Frontier Brewing Co.",
        "description": "Frontier Brewing Company and Taproom is a unique microbrewery located in the heart of downtown Casper. Frontier specializes in small-batch beer brewed fresh each week and served via an innovative self-pour tap system. In addition to fantastic beers, patrons will also find live entertainment, popular board games, food trucks, and a come-as-you-are vibe that is sure to make a stop at Frontier the best part of your week.",
        "logoURL": "Frontier.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "117 E 2nd St\n Casper, WY 82601",
        "phone": "(307) 337-1000",
        "tel": "307-337-1000",
        "siteURL": "https://frontierbrewingcompany.com",
        "checked": false
    },
    {
        "objectId": "Tivy0fC62Z",
        "placeId": "ChIJF7fSTaG8YIcRKApZf5tVkIo",
        "name": "The Gaslight Social",
        "description": "Looking for a place to have your next lunch meeting, or to blow off steam after work? Gaslight Social has the perfect atmosphere for whatever you need. Serving counter-style burritos from Bohemian Burrito, 37 beers on draft, arcade games, golf simulators & a great outdoor space, you won't run out of things to eat, drink or do.",
        "logoURL": "gaslight_logo.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "314 W Midwest Avenue\n Casper WY 82601",
        "phone": "(307) 337-1396",
        "tel": "307-337-1396",
        "siteURL": "https://www.thegaslightsocial.com/",
        "checked": false
    },
    {
        "objectId": "JmpxCV8oeN",
        "placeId": "ChIJLRhxOqG8YIcRpR4mtfMYUfk",
        "name": "The Office",
        "description": "We have designed the perfect place to socialize, dine, drink and play. Play a game of Buzztime trivia or just kick back and enjoy the game. We have 16 Tvs with Direct Tv so you can always catch your favorite team. We feature a wide variety of delectable dishes and tantalizing beverage options in our extensive menus. Enjoy a warm welcome from our professional and attentive staff every time you walk into our bar. We also feature weekly specials events and our place is perfect for late night food with variety of incredible selection of bottled beer and famous cocktails, wine. Once you settle in and browse our menu, all you have to do is decide what’s for dinner. Whether it’s our famous burger, or a flavorful Greek gyro roll, you’re sure to leave the table feeling satisfied. Visit us to enjoy a great time and experience the fun vibe in an relaxed and easy-going environment.",
        "logoURL": "office_logo.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "520 S. Ash Street\n Casper WY 82601",
        "phone": "(307) 265-2616",
        "tel": "307-265-2616",
        "siteURL": "https://theofficebarandgrill.pub/menu",
        "checked": false
    },
    {
        "objectId": "oyH4KmK2v3",
        "placeId": "ChIJ-4zDUaG8YIcR-3ZizfoiqQ0",
        "name": "Raccas",
        "description": "Racca’s Pizzeria Napoletana grew out of the simple idea that passion makes great pizza. \n\nMark Dym and Kristy Latorraca Dym opened their first pizzeria in Denver, CO in 2008 to celebrate the tradition of fine Neapolitan pizza. Instead of conforming to the American styles of pizza, Mark went directly to the source to master the art of true pizza-making from Naples, Italy - the birthplace of pizza\n\nBy training under the best Pizzaiuoli master craftsmen in the world, Mark and his team were awarded certification by the Associazione Verace Pizza Napoletana (VPN), being the first in Colorado and to this day the only certified Neapolitan pizzeria in the state of Wyoming! \n\nMark and Kristy came to Casper after friends shared what a unique and thriving city it is! They are committed to bringing the best parts of Old-World quality food and service in a dynamic, fun, inviting atmosphere where you experience a true taste of Italian hospitality, No Passport Required. \n\nDine with us and enjoy Wood-fired Pizzas, Pastas & Entrées, Wings, Salads and decadent Desserts all made from-scratch in house.",
        "logoURL": "racca_logo.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "430 S. Ash Street\n Casper WY 82601",
        "phone": "(307) 337-2444",
        "tel": "307-337-2444",
        "siteURL": "https://www.raccaspizzeria.com/#pizza-fired-by-passion",
        "checked": false
    },
    {
        "objectId": "ISNlELgQep",
        "placeId": "ChIJF7fSTaG8YIcRKApZf5tVkIo",
        "name": "Skull Tree Brewing inside Gaslight Social ",
        "description": "Skull Tree Brewing is a joint venture between four founders with a mission to make great beer.  They consider themselves a boutique brewery specializing in season, one-off & experimental beers.  Skull Tree Brewing beers all have local roots, from their High Plains Harvester showcasing locally grown hops, to their flagship beer County 1 with notes of sweet honey from Worland.  Ask any of the founders which beer is their favorite and the variety in beer options alone will ensure you get a different answer from all of them. ",
        "logoURL": "Skulltree.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "314 W Midwest Avenue\n Casper WY 82601",
        "phone": "(307) 277-3764",
        "tel": "307-277-3764",
        "siteURL": "https://www.facebook.com/SkullTreeBrewing/",
        "checked": false
    },
    {
        "objectId": "SgHhGA5MXD",
        "placeId": "ChIJa-zxSKG8YIcRfV2jV7rdL8g",
        "name": "Urban Bottle",
        "description": "Urban Bottle Wine & Spirits is a boutique wine, beer, and spirits shop in the Old Yellowstone District in Casper Wyoming!",
        "logoURL": "urbanbottle_logo.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "410 S. Ash Street\n Casper WY 82601",
        "phone": "(307) 333-6424",
        "tel": "307-333-6424",
        "siteURL": "https://www.facebook.com/urbanbottlewyo/",
        "checked": false
    },
    {
        "objectId": "fnh1HmqmIr",
        "placeId": "ChIJ7eHiBqS8YIcR1MVI-8Jcbfk",
        "name": "Yellowstone Garage",
        "description": "What was once an old gas station and auto garage is now a family-friendly American restaurant in Casper, WY. Yellowstone Garage is an upscale bar and grille that features homemade American food, live music, and entertainment.",
        "logoURL": "yellowstonegarage_logo.png",
        "createdAt": "2021-08-24T19:47:20.999Z",
        "updatedAt": "2021-09-23T22:26:06.089Z",
        "greyLogoURL": "https://oktoberfest-logos.s3.us-west-2.amazonaws.com/Gruner_g.png",
        "active": true,
        "location": "raccas",
        "address": "355 W Yellowstone Hwy\n Casper, WY 82601",
        "phone": "(307) 215-7266",
        "tel": "307-215-7266",
        "siteURL": "https://yellowstonegarage.com/",
        "checked": false
    },
]