<template>
  <v-bottom-navigation app :value="value" background-color="#12b25e" dark grow>
    <router-link to="/scan">
      <v-img
        :lazy-src="require('@/assets/images/scan.png')"
        contain="true"
        max-height="45"
        max-width="38"
        :src="require('@/assets/images/scan.png')"
        class="scan"
      ></v-img>
    </router-link>
    <v-spacer></v-spacer>
    <router-link to="/">
      <v-img
        :lazy-src="require('@/assets/images/homebutton.png')"
        contain="true"
        max-height="45"
        max-width="38"
        :src="require('@/assets/images/homebutton.png')"
      ></v-img>
    </router-link>
    <v-spacer />
    <router-link to="/passport">
      <v-img
        :lazy-src="require('@/assets/images/pubs_icon.png')"
        contain="true"
        max-height="45"
        max-width="38"
        :src="require('@/assets/images/pubs_icon.png')"
      ></v-img>
    </router-link>
    <v-spacer />
    <router-link to="/info">
      <v-img
        :lazy-src="require('@/assets/images/info.png')"
        contain="true"
        max-height="45"
        max-width="38"
        :src="require('@/assets/images/info.png')"
      ></v-img>
    </router-link>
    <v-spacer />
    <router-link to="/directions">
      <v-img
        :lazy-src="require('@/assets/images/directions.png')"
        contain="true"
        max-height="45"
        max-width="38"
        :src="require('@/assets/images/directions.png')"
      ></v-img>
    </router-link>
  </v-bottom-navigation>
</template>

<style scoped>
.v-image {
  z-index: 0;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
<script>
export default {
  data: () => ({ value: 1 }),
};
</script>